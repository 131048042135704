body {
  font-family:"Open Sans", Helvetica, Arial, Verdana, sans-serif;
  font-style:normal;
  font-size:16px;
  line-height:26px;
  word-spacing: normal;
  color: #696a6a;
  font-weight:300;
}

.top_area{ padding:13px 15px; float:left; width:100%}
.branding{ float:left;}
.sm_logo{ display:none;padding:0 15px 15px 0; height:auto}
.top_menu{ float:right; margin-top:17px; margin-right:1px;}

.top_menu ul{ list-style:none}
.top_menu li{ display:inline; padding:10px;}
.top_menu li a{ text-decoration:none}

.top_menu li:hover{ background:#eee;}
.content_area {
	margin:20px 0 60px;
}

.footer{background:#191919}
.footer .row {
	padding: 15px 0;
	font-size: 13px;
	line-height: 20px;
}
.footer p{ margin:0}
.f_left {
	color: #fff;
}
.f_right {
	text-align: right;
	color: #fff;
}
